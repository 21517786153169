<template>
  <input
    type="text"
    :value="date"
    :name="name"
    autocomplete="off"
    :class="datepickerclass"
    :placeholder="label"
    :disabled="disabled"
  />
</template>
<script>
import "nepali-date-picker/src/jquery.nepaliDatePicker.js";
import { englishToNepaliNumber, nepaliToEnglishNumber } from "nepali-number";
import { BSToAD } from "bikram-sambat-js";

export default {
  name: "nepali-date-picker",
  props: {
    value: { type: String, required: false, default: "" },
    label: { type: String, required: false, default: "" },
    name: { required: false, default: "date" },
    datepickerclass: { type: String, required: false, default: "date-pick" },
    minDate: { type: String, required: false, default: "" },
    maxDate: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
  },
  data: () => ({
    date: "",
    today: new Date().toISOString().slice(0, 10),
    monthsMap: [
      "बैशाख",
      "जेठ",
      "असार",
      "सावन",
      "भदौ",
      "असोज",
      "कार्तिक",
      "मंसिर",
      "पौष",
      "माघ",
      "फागुन",
      "चैत",
    ],
  }),
  watch: {
    value: {
      handler(value) {
        if (value === "") {
          this.date = "";
          return "";
        }
        let dateParts = value.split(" ");
        let nepaliYear = englishToNepaliNumber(dateParts[2]);
        let nepaliMonth = englishToNepaliNumber(dateParts[1]);
        let nepaliDay = englishToNepaliNumber(dateParts[0]);
        this.date = `${nepaliDay} ${nepaliMonth} ${nepaliYear}`;
      },
      immediate: true,
    },
  },
  mounted() {
    this.onMount();
  },
  methods: {
    onMount() {
      const vm = this;
      let settings = {
        dateFormat: "%d %M %y",
        closeOnDateSelect: false,
      };

      if (this.minDate) {
        settings.minDate = this.minDate;
      }

      if (this.maxDate) {
        settings.maxDate = this.maxDate;
      }
      let element = this.$el;
      // eslint-disable-next-line
      $(element)
        .nepaliDatePicker(settings)
        .on("dateChange", (event) => {
          this.formatDate(event.target.value);
          vm.$emit("nepaliDate", event.target.value);
        })
        .on("dateSelect", (event) => {
          this.formatDate(event.target.value);

          vm.$emit("nepaliDate", event.target.value);
        });
      // eslint-disable-next-line
      $(element).attr("readonly", false);
    },
    formatDate(date) {
      let dateParts = date.split(" ");
      let year = dateParts[2];
      let month = this.getDoubleDigitDateFormat(dateParts[1]);
      let day = this.getDoubleDigitDateFormat(dateParts[0]);
      let englishYear = nepaliToEnglishNumber(year);
      let englishMonth = nepaliToEnglishNumber(month);
      let englishDay = nepaliToEnglishNumber(day);
      let nameOfMonth = this.getMonthPart(dateParts[1]);
      let test = `${englishYear}-${nameOfMonth}-${englishDay}`;
      this.$emit("englishDate", BSToAD(test));
      return `${englishDay} ${englishMonth} ${englishYear}`;
    },
    getDoubleDigitDateFormat(datePart) {
      return datePart.length > 1
        ? datePart
        : englishToNepaliNumber(0) + `${datePart}`;
    },
    getMonthPart(month) {
      let number = this.monthsMap.indexOf(month) + 1;
      return number > 9 ? number : "0" + number;
    },
  },
};
</script>
<style scoped>
@import "~nepali-date-picker/dist/nepaliDatePicker.min.css";
</style>
