import axios from "axios";

const baseDomain = process.env.VUE_APP_GRMS_DOMAIN;
const baseURL = `${baseDomain}/api`; // Incase of /api/v1;

// ALL DEFUALT CONFIGURATION HERE

export default axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});
