<template>
  <div class="grievance-card grievance-form">
    <h3 class="mb-24">
      गुनासो र प्रतिक्रिया बारे जानकारी प्रदान गर्न तलको फारम भर्नु होस्
    </h3>
    <div class="flex">
      <div
        class="mb-24 field-wrapper"
        :class="{ 'form-errors': this.validationErrors['reporter_info.name'] }"
      >
        <label for="" class="mb-8">नाम</label>
        <input
          v-model="formData.reporter_info.name"
          type="text"
          placeholder="पूरा नाम लेख्नुहोस्"
        />
        <span
          class="validation-error"
          v-if="this.validationErrors['reporter_info.name']"
          >{{ this.validationErrors["reporter_info.name"][0] }}</span
        >
      </div>
      <div
        class="mb-24 field-wrapper"
        :class="{ 'form-errors': this.validationErrors['reporter_info.email'] }"
      >
        <label for="" class="mb-8">इमेल</label>
        <input
          v-model="formData.reporter_info.email"
          type="text"
          placeholder="इमेल लेख्नुहोस्"
        />
        <span
          class="validation-error"
          v-if="this.validationErrors['reporter_info.email']"
          >{{ this.validationErrors["reporter_info.email"][0] }}</span
        >
      </div>
    </div>
    <div class="flex">
      <div
        class="mb-24 field-wrapper"
        :class="{
          'form-errors': this.validationErrors['reporter_info.phone_no'],
        }"
      >
        <label for="" class="mb-8">फोन नम्बर</label>
        <input
          v-model="formData.reporter_info.phone_no"
          type="number"
          placeholder="फोन नम्बर लेख्नुहोस्"
        />
        <span
          class="validation-error"
          v-if="this.validationErrors['reporter_info.phone_no']"
          >{{ this.validationErrors["reporter_info.phone_no"][0] }}</span
        >
      </div>
      <div class="mb-24 field-wrapper">
        <label for="" class="mb-8">ठेगाना</label>
        <input
          v-model="formData.reporter_info.address"
          type="text"
          placeholder="पूरा ठेगाना लेख्नुहोस्"
        />
      </div>
    </div>
    <div class="mb-24 field-wrapper">
      <label for="" class="mb-8">क्षेत्र</label>
      <Multiselect
        v-model="formData.sector_type"
        :options="selectOptions"
        :placeholder="'क्षेत्र छान्नुहोस्'"
        :canClear="false"
        label="label"
      />
    </div>
    <div
      class="mb-24 field-wrapper"
      :class="{ 'form-errors': this.validationErrors['description'] }"
    >
      <label for="" class="mb-8">विवरण <span class="required">*</span></label>
      <textarea
        v-model="formData.description"
        name=""
        id=""
        cols="30"
        rows="10"
        placeholder="विवरण लेख्नुहोस्"
      ></textarea>
      <span
        class="validation-error"
        v-if="this.validationErrors['description']"
        >{{ this.validationErrors["description"][0] }}</span
      >
    </div>
    <div class="flex flex-btn mb-16">
      <button
        type="button"
        @click.prevent="postGrievance"
        :class="formsubmitting ? 'btn btn-primary active' : 'btn btn-primary'"
      >
        <span class="btn-loader" v-if="formsubmitting"></span>
        पठाउनुहोस्
      </button>
      <button
        type="button"
        class="btn btn-primary btn-primary--light"
        @click.prevent="resetFormData"
      >
        हटाउनुहोस्
      </button>
    </div>
    <p>* यो फिल्ड भर्न आवश्यक छ।</p>
  </div>
</template>

<script>
import GrievanceRepository from "../../repositories/GrievanceRepository";
import Multiselect from "@vueform/multiselect";
export default {
  name: "grievanceForm",
  props: {
    type: { required: false, type: String, default: "" },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      formsubmitting: false,
      formData: {
        reporter_info: {
          name: "",
          phone_no: "",
          email: "",
          address: "",
        },
        title: "",
        description: "",
        sector_type: this.type,
      },
      validationErrors: {},
      selectOptions: [
        {
          label: "स्वास्थ्य क्षेत्र",
          value: "health",
        },
        {
          label: "कृषि क्षेत्र",
          value: "agriculture",
        },
        {
          label: "शिक्षा क्षेत्र",
          value: "education",
        },
        {
          label: "पानी र सरसफाइ क्षेत्र",
          value: "water_sanitation",
        },
      ],
    };
  },
  methods: {
    async postGrievance() {
      this.formsubmitting = true;
      let { data } = await GrievanceRepository.postGrievance(this.formData);
      this.formsubmitting = false;

      if (data.status == false && data.code == 422) {
        this.validationErrors = data.errors;
      }

      if (data.status == false && data.code == 0) {
        this.$toast.error(`तपाईंको गुनासो सुरक्षित गर्न सकिएन`);
      }

      if (data.status == true) {
        this.$toast.success(`तपाईंको गुनासो सफलतापूर्वक सुरक्षित गरियो`);
        this.resetValidationErrors();
        this.resetFormData();
      }
    },
    resetFormData() {
      this.formData = {
        reporter_info: {
          name: "",
          phone_no: "",
          email: "",
          address: "",
        },
        title: "",
        description: "",
        sector_type: "",
      };
    },
    resetValidationErrors() {
      this.validationErrors = {};
    },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
.form-errors {
  label,
  .validation-error {
    background: -webkit-linear-gradient(#ff9d6c, #bb4e75);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  input,
  textarea {
    border: 1px solid #f55478;
  }
}
.required {
  color: #f55478;
}

.grievance-form {
  background: $neutrals-white;
  border-radius: 20px;
  padding: 80px 120px;
  position: relative;
  .flex {
    display: flex;
  }
  @media all and (max-width: 768px) {
    padding: 40px;
  }
  @media all and (max-width: 600px) {
    padding: 40px 20px;
    .flex {
      display: block;
    }
    .btn {
      width: 100%;
      & + .btn {
        margin-top: 16px;
      }
    }
  }
  .flex:not(.flex-btn) {
    justify-content: space-between;
    > .field-wrapper {
      width: 48%;
      @media all and (max-width: 600px) {
        width: 100%;
      }
    }
  }
  .flex-btn + p {
    background: -webkit-linear-gradient(#ff9d6c, #bb4e75);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Mukta-SemiBold";
  }
}

.field-wrapper {
  label {
    display: block;
    font-family: "Mukta-SemiBold";
  }
  input,
  textarea {
    border: 1px solid #c9ccd4;
    border-radius: 8px;
    height: 46px;
    padding: 10px 20px;
    width: 100%;
  }

  textarea {
    height: 150px;
  }
}

.btn-primary.active {
  opacity: 0.5;
}
</style>
