<template>
  <div class="container">
    <div class="flex mb-40 datepicker-filter">
      <h3>गुनासो/ प्रतिक्रिया विभाजन</h3>
      <div class="flex">
        <NepaliDatePicker
          :value="daterange.startBS"
          label="सुरू मिति"
          @englishDate="daterange.startAD = $event"
          @nepaliDate="daterange.startBS = $event"
          :key="keyStartDate"
          :maxDate="StartDateMaxDate"
        />
        <NepaliDatePicker
          :value="daterange.endBS"
          label="अन्त्य मिति"
          @englishDate="daterange.endAD = $event"
          @nepaliDate="daterange.endBS = $event"
          :key="keyEndDate"
          :minDate="EndDateMinDate"
        />
        <span class="reset" @click.stop="resetFilter">
          <Reset />
          रिसेट</span
        >
      </div>
    </div>
    <div class="grievance-card mb-40">
      <p>
        <strong>{{ englishToNepaliNumber(this.total) }}</strong> कुल गुनासो/
        प्रतिक्रिया प्राप्त
      </p>
      <div class="loader" v-if="datafetching"></div>
      <BarChart
        v-else
        :data="grievanceStatus"
        :datatype="'grievance'"
        :gradient="gradient"
        :noData="statusData"
      />
    </div>
    <div class="grievance-card mb-40">
      <p>कुल गुनासो/प्रतिक्रिया सम्बन्धित स्थिति</p>
      <div class="loader" v-if="datafetching"></div>
      <HorizontalBarChart
        v-else
        :data="grievanceRelatedStatus"
        :datatype="'grievance'"
        :noData="relatedStatusData"
      />
    </div>
    <GrievanceStats
      v-if="!datafetching"
      :totalCount="englishToNepaliNumber(grievancetotal)"
      :ongoingCount="englishToNepaliNumber(ongoingclosed.ongoing)"
      :closedCount="englishToNepaliNumber(ongoingclosed.closed)"
    />
    <div class="grievance-card mb-40">
      <p>
        <strong>{{ englishToNepaliNumber(this.total) }}</strong> कुल गुनासो/
        प्रतिक्रिया प्राप्त भयेको श्रोत
      </p>
      <div class="loader" v-if="datafetching"></div>
      <BarChart
        v-else
        :data="grievanceOrigin"
        :datatype="'grievance'"
        :noData="sourceData"
      />
    </div>
    <div class="grievance-card mb-40">
      <p class="mb-24">मोबाइल एपबाट प्राप्त गुनासो/ प्रतिक्रिया</p>
      <div class="flex">
        <div class="grievance-stats">
          <div class="form mb-48">
            <strong>{{ englishToNepaliNumber(mobile_stat.app) }}</strong
            ><span>फारम</span>
          </div>
          <div class="audio mb-48">
            <strong>{{ englishToNepaliNumber(mobile_stat.audio) }}</strong
            ><span>अडियो</span>
          </div>
        </div>
        <div class="flex">
          <RadialBarChart :label="'फारम'" :percentage="appPercent" />
          <RadialBarChart
            :label="'अडियो'"
            :percentage="audioPercent"
            :gradient="{ from: '#FD6E6A', to: '#FFC600' }"
          />
        </div>
      </div>
    </div>
    <GrievanceForm :type="sectorType"></GrievanceForm>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import GrievanceForm from "../../components/grievance/grievanceForm.vue";
import GrievanceRepository from "../../repositories/GrievanceRepository";
import { englishToNepaliNumber } from "nepali-number";
import NepaliDatePicker from "./../../components/shared/NepaliDatePicker.vue";
import { getDatepickerFormat } from "./../../helper/helper";
import Reset from "./../../components/shared/Reset.vue";
const BarChart = defineAsyncComponent(() =>
  import("../../components/BarChart.vue")
);
const HorizontalBarChart = defineAsyncComponent(() =>
  import("../../components/HorizontalBarChart.vue")
);
const RadialBarChart = defineAsyncComponent(() =>
  import("../../components/RadialBarChart.vue")
);
const GrievanceStats = defineAsyncComponent(() =>
  import("../../components/grievance/grievanceStats.vue")
);

export default {
  props: {
    sectorType: { type: String, required: false, default: null },
  },
  components: {
    GrievanceForm,
    BarChart,
    HorizontalBarChart,
    RadialBarChart,
    GrievanceStats,
    NepaliDatePicker,
    Reset,
  },
  data() {
    return {
      datafetching: true,
      keyStartDate: 1,
      keyEndDate: 1,
      daterange: {
        startBS: "",
        startAD: "",
        endBS: "",
        endAD: "",
      },
      englishToNepaliNumber,
      total: 0,
      grievancetotal: 0,
      mobile_stat: {
        total: 0,
        app: 0,
        audio: 0,
      },
      ongoingclosed: {
        ongoing: 0,
        closed: 0,
      },
      grievanceStatus: [],
      grievanceOrigin: [],
      grievanceRelatedStatus: [],
      gradient: {
        to: "#BB4E75",
        from: "#FF9D6C",
      },
      label: {
        new: "वर्गिकरण गर्न बाँकी",
        grievance: "गुनासो",
        feedback: "प्रतिक्रिया",
        suggestion: "सुझाव",
        invalid_not_related: "आसम्बन्धित/अमान्य",
        mobile: "मोबाइल एपबाट आएको/भरेको",
        online: "अनलाइन आएको",
        manual: "कार्यालयमा आएको/भरेको",
        related: "स्थानिय तहसँग सम्बन्धित",
        not_related: "स्थानिय तहसँग असम्बन्धित",
        forwarded: "जिल्ला/प्रदेश/संघीय सरकारसंग सम्बन्धित छ",
        invalid: "अमान्य",
      },
    };
  },
  methods: {
    async getGrievanceRepo(params = "") {
      try {
        let { data } = await GrievanceRepository.getGrievanceStatus(params);
        let grievanceOrder = {
          गुनासो: 0,
          प्रतिक्रिया: 1,
          सुझाव: 2,
          "वर्गिकरण गर्न बाँकी": 3,
        };
        this.$store.dispatch("setLoading", false);
        if (data.status) {
          this.grievanceStatus = data.data.grievance_status.map((ele) => {
            return {
              category: this.label[ele.category] || ele.category,
              total: ele.total,
            };
          });

          this.grievanceStatus = this.grievanceStatus.sort(
            (a, b) => grievanceOrder[a.category] - grievanceOrder[b.category]
          );

          this.grievanceRelatedStatus = data.data.related_status.map((ele) => {
            return {
              category: this.label[ele.category] || ele.category,
              total: ele.total,
            };
          });

          this.grievanceOrigin = data.data.origin_status.map((ele) => {
            return {
              category: this.label[ele.category] || ele.category,
              total: ele.total,
            };
          });

          this.total = data.data.total;
          this.grievancetotal = data.data.grievance_total;

          for (const i in data.data.ongoing_closed_status) {
            this.ongoingclosed[data.data.ongoing_closed_status[i].category] =
              data.data.ongoing_closed_status[i].total;
          }

          this.mobile_stat.total = 0;
          for (const i in data.data.mobile_origin_status) {
            this.mobile_stat[data.data.mobile_origin_status[i].category] =
              data.data.mobile_origin_status[i].total;
            this.mobile_stat.total += data.data.mobile_origin_status[i].total;
          }
        }
        this.datafetching = false;
      } catch (err) {
        this.$store.dispatch("setLoading", false);
        this.datafetching = false;
      }
    },
    resetFilter() {
      this.daterange = {
        startBS: "",
        startAD: "",
        endBS: "",
        endAD: "",
      };
    },
  },
  computed: {
    statusData() {
      return this.grievanceStatus.length ? false : true;
    },
    relatedStatusData() {
      return this.grievanceRelatedStatus.length ? false : true;
    },
    sourceData() {
      return this.grievanceOrigin.length ? false : true;
    },
    appPercent() {
      if (this.mobile_stat.total == 0) {
        return 0;
      }
      return (this.mobile_stat.app / this.mobile_stat.total) * 100;
    },
    audioPercent() {
      if (this.mobile_stat.total == 0) {
        return 0;
      }
      return (this.mobile_stat.audio / this.mobile_stat.total) * 100;
    },
    StartDateMaxDate() {
      // eslint-disable-next-line
      this.keyStartDate++;
      if (this.daterange.endAD) {
        return getDatepickerFormat(this.daterange.endAD);
      }

      return "";
    },
    EndDateMinDate() {
      // eslint-disable-next-line
      this.keyEndDate++;
      if (this.daterange.startAD) {
        return getDatepickerFormat(this.daterange.startAD);
      }

      return "";
    },
  },
  watch: {
    daterange: {
      handler(val) {
        this.datafetching = true;

        let param = "";
        if (this.sectorType) {
          param += `sector_type=${this.sectorType}&`;
        }

        if (val.startAD && val.endAD) {
          param = `start_date=${val.startAD}&end_date=${val.endAD}`;
        }

        this.getGrievanceRepo(param);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.grievance {
  &-wrapper {
    padding-bottom: 80px;
    .container {
      max-width: 875px;
      padding: 0 20px;
    }
    h3 {
      font-family: "Mukta-SemiBold";
    }

    p strong {
      color: $primary;
      font-size: 40px;
      font-family: "Mukta-Bold";
      margin-right: 10px;
    }
  }
  &-stats {
    font-size: 18px;
    width: 200px;
    @media all and (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      > .form,
      > .audio {
        margin-bottom: 20px;
      }
    }
    & + .flex {
      width: calc(100% - 200px);
      @media all and (max-width: 480px) {
        display: block;
        width: 100%;
      }
      .vue-apexcharts {
        margin-left: -80px;
        @media all and (min-width: 768px) and (max-width: 900px) {
          margin-left: -110px;
        }
        @media all and (max-width: 600px) {
          margin-left: -40px;
        }
        @media all and (max-width: 480px) {
          margin-left: 20px;
        }
      }
      .apexcharts-canvas {
        @media all and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  &-card {
    background: $neutrals-white;
    border-radius: 20px;
    min-height: 400px;
    padding: 50px 100px;
    position: relative;
    @media all and (max-width: 768px) {
      padding: 40px;
      > .flex {
        display: block;
        width: 100%;
      }
    }
    @media all and (max-width: 480px) {
      padding: 40px 20px;
    }
    > .flex {
      align-items: center;
    }
    .form,
    .audio {
      strong {
        display: block;
        font-size: 24px;
        font-family: "Mukta-Bold";
      }
      span {
        &:before {
          border-radius: 50%;
          content: "";
          display: inline-block;
          height: 12px;
          margin-right: 6px;
          width: 12px;
        }
      }
    }
    .form span:before {
      background: linear-gradient(135deg, #f97794 0%, #623aa2 100%);
    }
    .audio span:before {
      background: linear-gradient(135deg, #fd6e6a 0%, #ffc600 100%);
    }
  }
}

.datepicker-filter {
  justify-content: space-between;
  @media all and (max-width: 768px) {
    display: block;
    > .flex {
      margin-top: 16px;
    }
  }
  @media all and (max-width: 480px) {
    > .flex {
      display: block;
    }
  }
}

.date-pick {
  background: transparent;
  border: 1px solid $primary;
  border-radius: 24px;
  color: $primary;
  font-family: "Mukta-SemiBold";
  margin-right: 16px;
  padding: 0 24px;
  width: 224px;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $primary;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $primary;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $primary;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $primary;
  }
  @media all and (max-width: 768px) {
    height: 40px;
    width: 40%;
  }
  @media all and (max-width: 480px) {
    margin-bottom: 16px;
    width: 70%;
  }
}

.current-month-date.active,
.drop-down-content li.active,
.nepali-date-picker table tbody td.current-month-date:hover {
  background: $primary;
}

.reset {
  background: transparent;
  color: $primary;
  cursor: pointer;
  line-height: 36px;
  svg {
    margin-right: 4px;
    margin-top: -4px;
    vertical-align: middle;
  }
}
</style>
