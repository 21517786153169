<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.13641 7.00973H2.93485C2.8414 7.00979 2.74886 6.99142 2.66252 6.95569C2.57618 6.91995 2.49773 6.86755 2.43165 6.80147C2.36558 6.73539 2.31317 6.65694 2.27744 6.5706C2.2417 6.48426 2.22334 6.39172 2.22339 6.29827V3.09671C2.22339 2.90802 2.29835 2.72706 2.43177 2.59364C2.56519 2.46021 2.74616 2.38525 2.93485 2.38525C3.12354 2.38525 3.3045 2.46021 3.43792 2.59364C3.57135 2.72706 3.64631 2.90802 3.64631 3.09671V5.58682H6.13641C6.3251 5.58682 6.50606 5.66177 6.63949 5.7952C6.77291 5.92862 6.84787 6.10958 6.84787 6.29827C6.84787 6.48697 6.77291 6.66793 6.63949 6.80135C6.50606 6.93478 6.3251 7.00973 6.13641 7.00973Z"
      fill="#458FF6"
    />
    <path
      d="M14.9406 10.2113C14.8471 10.2114 14.7546 10.193 14.6683 10.1573C14.5819 10.1215 14.5035 10.0691 14.4374 10.003C14.3713 9.93697 14.3189 9.85852 14.2832 9.77218C14.2474 9.68583 14.2291 9.59329 14.2291 9.49985C14.2299 8.24715 13.8171 7.02923 13.0549 6.03513C12.2926 5.04103 11.2235 4.32637 10.0135 4.00208C8.80354 3.67778 7.52033 3.76199 6.3631 4.24164C5.20586 4.72129 4.23933 5.56954 3.61353 6.65473C3.56672 6.73557 3.50445 6.80639 3.43027 6.86317C3.35609 6.91994 3.27145 6.96154 3.18119 6.98561C2.9989 7.03421 2.80477 7.0084 2.64151 6.91387C2.47825 6.81933 2.35923 6.66381 2.31063 6.48152C2.26203 6.29924 2.28784 6.10511 2.38237 5.94185C3.165 4.5857 4.37336 3.52578 5.81994 2.92657C7.26652 2.32735 8.87044 2.22236 10.3828 2.62786C11.8951 3.03337 13.2314 3.92671 14.1841 5.16926C15.1369 6.4118 15.6529 7.93407 15.652 9.49985C15.6521 9.59329 15.6337 9.68583 15.598 9.77218C15.5623 9.85852 15.5099 9.93697 15.4438 10.003C15.3777 10.0691 15.2993 10.1215 15.2129 10.1573C15.1266 10.193 15.034 10.2114 14.9406 10.2113V10.2113ZM14.1402 16.6144C14.0468 16.6145 13.9542 16.5961 13.8679 16.5604C13.7815 16.5246 13.7031 16.4722 13.637 16.4062C13.5709 16.3401 13.5185 16.2616 13.4828 16.1753C13.447 16.089 13.4287 15.9964 13.4287 15.903V13.4129H10.9386C10.7499 13.4129 10.569 13.3379 10.4356 13.2045C10.3021 13.0711 10.2272 12.8901 10.2272 12.7014C10.2272 12.5127 10.3021 12.3318 10.4356 12.1983C10.569 12.0649 10.7499 11.99 10.9386 11.99H14.1402C14.2336 11.9899 14.3262 12.0083 14.4125 12.044C14.4989 12.0797 14.5773 12.1321 14.6434 12.1982C14.7095 12.2643 14.7619 12.3427 14.7976 12.4291C14.8333 12.5154 14.8517 12.608 14.8517 12.7014V15.903C14.8517 15.9964 14.8333 16.089 14.7976 16.1753C14.7619 16.2616 14.7095 16.3401 14.6434 16.4062C14.5773 16.4722 14.4989 16.5246 14.4125 16.5604C14.3262 16.5961 14.2336 16.6145 14.1402 16.6144V16.6144Z"
      fill="#458FF6"
    />
    <path
      d="M8.53743 16.6144C6.65121 16.6122 4.84288 15.8619 3.50911 14.5281C2.17535 13.1943 1.42507 11.386 1.42285 9.49979C1.42285 9.3111 1.49781 9.13014 1.63123 8.99671C1.76466 8.86329 1.94562 8.78833 2.13431 8.78833C2.323 8.78833 2.50396 8.86329 2.63739 8.99671C2.77081 9.13014 2.84577 9.3111 2.84577 9.49979C2.84498 10.7525 3.25775 11.9704 4.02001 12.9645C4.78226 13.9586 5.85136 14.6733 7.06136 14.9976C8.27135 15.3219 9.55456 15.2376 10.7118 14.758C11.869 14.2784 12.8356 13.4301 13.4614 12.3449C13.5559 12.1816 13.7114 12.0626 13.8937 12.014C14.076 11.9654 14.2701 11.9912 14.4334 12.0858C14.5966 12.1803 14.7157 12.3358 14.7643 12.5181C14.8129 12.7004 14.7871 12.8945 14.6925 13.0578C14.0668 14.1367 13.1692 15.0328 12.0893 15.6569C11.0093 16.2809 9.7847 16.611 8.53743 16.6144V16.6144Z"
      fill="#458FF6"
    />
  </svg>
</template>
